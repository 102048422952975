<template>
  <div>
    <NAVTOP ref="topRef"></NAVTOP>
    <!-- 渲染时增加参数的key值防止同页面不刷新问题  存在问题 相同传参页面不刷新-->
    <div class="leftbox">
      <router-view :key="$route.params"></router-view>
    </div>
    <certification ref="certificationRef" />
    <updateEntInfo ref="updateEntInfoRef" />
    <Detailsdrawer ref="detailsRef" @withdrawEmit=""></Detailsdrawer>
  </div>
</template>
<script setup>
import { reactive,watch, ref, unref,onMounted,nextTick } from 'vue';
import NAVTOP from "./component/navTop/navTop2.vue";
import certification from "@/components/dialog/certification.vue"
import updateEntInfo from "@/components/dialog/updateEntInfo.vue"
import { useStore } from "vuex";
import Detailsdrawer from "@/views/contractDocuments/components/details.vue"//详情
const store = useStore();
const state = reactive({
});
const detailsRef = ref();//详情弹框ref
const certificationRef = ref()
const updateEntInfoRef = ref()
const topRef = ref(null);//告警信息弹窗ref
watch(() => store.state.messageIndex, (value, oldValue) => {
  if(value){
    console.log('value',value)
    unref(detailsRef).getDetails(store.state.messageData)
  }
})
onMounted(()=>{
  nextTick(()=>{
    console.log("nvalayout.vue")
    unref(certificationRef).show()
    unref(updateEntInfoRef).show()
  })
})
</script>
<style lang="scss" scoped >
.leftbox {
  height: calc(100vh - 88px);
  overflow: auto;
}
</style>
